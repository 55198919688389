<template>
  <div>
    <!--pc端首页-->
    <div class="pc-layout">
      <!--视频模块-->
      <div class="video-module">
        <div class="video-module-container" :class="scrollOver?'video-module-complete':''" :style="{'transform': videoTransform, 'opacity': videoOpacity}">
          <div class="container-info">
            <video
                src="https://cycsmate.oss-cn-shenzhen.aliyuncs.com/video/miniVid1.mp4"
                autoplay
                loop
                muted
            ></video>
          </div>
        </div>
        <div class="video-module-content" :style="{'transform': contentTransform, 'opacity': contentOpacity, 'pointer-events': contentEvents}">
          <h1>{{ txt1 }}</h1>
          <div class="content-occupy"></div>
          <div class="content-understand">
            <button @click="onJumpDetail">
              <span>查看IP-BOX详情</span>
            </button>
          </div>
          <div class="content-occupy"></div>
          <div class="content-slider">
            <div class="content-slider-box">
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <!--轮播模块-->
      <div class="banner-module">
        <scale-banner></scale-banner>
      </div>
      <!--新闻模块-->
      <div class="news-module">
        <div class="news-module-info">
          <h2>{{ txt1 }}</h2>
          <div class="info-text">
            <span>{{ txt2 }}</span>
          </div>
        </div>
        <div class="news-module-boxes">
          <div class="box">
            <div class="box-title">
              <span>自主研发</span>
            </div>
            <div class="box-content">IP-BOX是100%由中国程序员自主研发，用于元宇宙的时空探索，追求更加丰富的视觉体验，助力人类探索元宇宙。</div>
            <div class="box-button">
              <button @click="onJumpDetail">了解更多</button>
            </div>
          </div>
          <div class="box">
            <div class="box-title">
              <span>技术先进</span>
            </div>
            <div class="box-content">门槛降低，探索元宇宙更加便捷，帮助学校政企、小微品牌和直播领域降低采购成本，使用方便，符合市场需求</div>
            <div class="box-button">
              <button @click="onJumpDetail">了解更多</button>
            </div>
          </div>
          <div class="box">
            <div class="box-title">
              <span>界面友好</span>
            </div>
            <div class="box-content">操作极其容易，无需专业人才方可操作，声光电融合、虚实交互结合的制片一体机，大大降低直播领域的采购成本</div>
            <div class="box-button">
              <button @click="onJumpDetail">了解更多</button>
            </div>
          </div>
        </div>
        <div class="news-module-example">
          <div class="example-title">
            <div class="example-title-left">超元创世新闻</div>
            <div class="example-title-right" @click="pageJump('/news')">查看所有新闻</div>
          </div>
          <div class="example-occupy"></div>
          <div class="example-imgs">
            <div class="example-imgs-box" v-for="item in newsList" :key="item.id">
              <div class="box-cover" @click="pageJump('/detail',item.id)">
                <img :src="item.vCover" alt="">
              </div>
            </div>
          </div>
          <div class="example-content">
            <div class="example-content-area" v-for="item in newsList" :key="item.id">
              <div class="area-tab">新闻</div>
              <div class="area-title" @click="pageJump('/detail',item.id)">
                <span>{{ item.title }}</span>
              </div>
              <div class="area-introduce">{{ item.subTitle }}</div>
              <div class="area-button" @click="pageJump('/detail',item.id)">
                <span>了解详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--社区模块-->
      <div class="community-module">
        <div class="community-module-background"></div>
        <div class="community-module-gradient"></div>
        <div class="community-module-content">
          <div class="content-occupy"></div>
          <div class="content-container">
            <div class="content-container-detail">
              <div class="detail-occupy"></div>
              <div class="detail-info">
                <div class="detail-info-title">Showings首映式</div>
                <div class="detail-info-text">Showings是一个致力于打造元宇宙数字资产商城的平台。它是一个由数字资产生产、交易和咨询服务构成的综合性平台，提供一站式数字资产商业服务。</div>
                <div class="detail-info-button">
                  <a href="https://showings.cn" target="_blank">
                    <button>
                      <span>浏览平台</span>
                    </button>
                  </a>
                </div>
              </div>
              <div class="detail-occupy"></div>
            </div>
          </div>
          <div class="content-occupy"></div>
        </div>
      </div>
      <!--展示模块-->
      <div class="reveal-module">
        <div class="reveal-module-container">
          <div class="container-show">
            <div class="container-show-box">
              <div class="box-image">
                <img src="https://oss.cycsmeta.com/images/230113/13/9409d016e4884cb4b73e7abe4dcf0ebe.png" alt="">
              </div>
              <div class="box-info">
                <div class="box-info-detail">
                  <a href="https://showings.cn" target="_blank">电动牙刷造型场景</a>
                  <div class="from">来自：小拉大</div>
                </div>
                <div class="box-info-avatar">
                  <img src="https://oss.cycsmeta.com/images/6800794448e140ebb4a75bcb20710a29.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="container-show">
            <div class="container-show-box">
              <div class="box-image2">
                <img src="https://oss.cycsmeta.com/images/230113/13/9224261c324c435693ecf72d0194d3fa.png" alt="">
              </div>
              <div class="box-info">
                <div class="box-info-detail">
                  <a href="https://showings.cn" target="_blank">冰川电视发布会造型场景</a>
                  <div class="from">来自：小拉大</div>
                </div>
                <div class="box-info-avatar">
                  <img src="https://oss.cycsmeta.com/images/6800794448e140ebb4a75bcb20710a29.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="container-show">
            <div class="container-show-box">
              <div class="box-image">
                <img src="https://oss.cycsmeta.com/images/230113/13/ff9d3ff964d54c6ab66ddd1f5188a6bf.png" alt="">
              </div>
              <div class="box-info">
                <div class="box-info-detail">
                  <a href="https://showings.cn" target="_blank">灯塔造型场景</a>
                  <div class="from">来自：L</div>
                </div>
                <div class="box-info-avatar">
                  <img src="https://oss.cycsmeta.com/images/17f6f50f09e54f65803e2ce0fe1ed176.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--获取模块-->
      <div class="obtain-module" style="padding: 0;">
        <div class="obtain-module-container">
          <div class="container-content">
            <!--            <div class="container-content-left">-->
            <!--              <div class="left-info">-->
            <!--                <div class="left-info-content">-->
            <!--                  <div class="content-title">免费下载，功能齐备！</div>-->
            <!--                  <div class="content-text">虚幻引擎功能齐备，开箱即用，并提供所有功能及完整的源代码访问权限。</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="container-content-right">-->
            <!--              <div class="right-info">-->
            <!--                <div class="right-info-box">-->
            <!--                  <div class="box-content">-->
            <!--                    <div class="box-content-top">获取虚幻引擎</div>-->
            <!--                    <div class="box-content-occupy"></div>-->
            <!--                    <div class="box-content-bottom">-->
            <!--                      <div class="bottom-title">免费开启虚幻之旅！</div>-->
            <!--                      <div class="bottom-text">你可以免费使用虚幻引擎创建线性内容、自定义项目和内部项目。它也可以被免费用于游戏开发——只有当你的产品营收超过100万美元时才需要支付5%的分成费用。</div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="right-info-box">-->
            <!--                  <div class="box-content">-->
            <!--                    <div class="box-content-top">和我们谈谈</div>-->
            <!--                    <div class="box-content-occupy"></div>-->
            <!--                    <div class="box-content-bottom">-->
            <!--                      <div class="bottom-title">自定义许可</div>-->
            <!--                      <div class="bottom-text">需要高级支持、自定义条款或与Epic Games建立直接联系吗？欢迎就企业许可或自定义解决方案与我们沟通。</div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="obtain-module-communication">
          <div class="communication-background"></div>
          <div class="communication-container">
            <!--            <div class="communication-container-content">-->
            <!--              <div class="content-title">新闻通讯</div>-->
            <!--              <div class="content-info">-->
            <!--                <div class="content-info-text">获取关于行业创新的新闻及最新免费资产</div>-->
            <!--                &lt;!&ndash;                <div class="content-info-select">&ndash;&gt;-->
            <!--                &lt;!&ndash;                  <div class="select-name">所属行业</div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                  <span class="select-arrow"></span>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              </div>-->
            <!--              <div class="content-subscribe">-->
            <!--                <div class="content-subscribe-input">-->
            <!--                  <input type="text" placeholder="电子邮件">-->
            <!--                </div>-->
            <!--                <div class="content-subscribe-button">-->
            <!--                  <button>-->
            <!--                    <span>订阅</span>-->
            <!--                  </button>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="content-explain">-->
            <!--                <p>通过提交您的信息，即表示您想收到超元创世的新闻、调查问卷和特惠推送。</p>-->
            <!--                <a href="">隐私政策</a>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <!--移动端-->
    <div class="mobile-layout">
      <!--产品应用模块-->
      <div class="apply-module">

        <div class="apply-module-background">
          <div class="background-container">
            <div class="container-info">
              <video
                  src="https://cycsmate.oss-cn-shenzhen.aliyuncs.com/video/miniVid1.mp4"
                  autoplay
                  loop
                  muted
              ></video>
            </div>
            <div class="background-container-mask"></div>
          </div>
        </div>
        <div class="apply-module-content">
          <div class="content-container">
            <h1>{{ txt1 }}</h1>
            <button @click="onJumpDetail">查看IP-BOX详情</button>
            <div class="content-container-slider">
              <div class="slider-box">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="apply-module-list">-->
        <!--          <div class="list-box">-->
        <!--            <div class="list-box-cover">-->
        <!--              <img src="https://cdn2.unrealengine.com/Unreal+Engine%2Findustry%2Fgames%2FFortnite_RayTracing_Top6_01-1920x1080-e6f951d944b0f6e3663bb385f96b25b6fcbae7ae.png?resize=1&w=518" alt="">-->
        <!--            </div>-->
        <!--            <div class="list-box-content">-->
        <!--              <h4>游戏</h4>-->
        <!--              <p>充满无限可能的世界</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="list-box">-->
        <!--            <div class="list-box-cover">-->
        <!--              <img src="https://cdn2.unrealengine.com/the-mandalorian-1920x1080-130245580-1920x1080-4e213a91a319.jpg?resize=1&w=518" alt="">-->
        <!--            </div>-->
        <!--            <div class="list-box-content">-->
        <!--              <h4>影视</h4>-->
        <!--              <p>制片，无需等待</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--&lt;!&ndash;          <div class="list-box">&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-cover">&ndash;&gt;-->
        <!--&lt;!&ndash;              <img src="https://cdn2.unrealengine.com/Unreal+Engine%2Findustries%2Farchitecture%2FCG-1920x1080-9389ad561469d83f702b5720f3cb0a37b633e83c.jpg?resize=1&w=518" alt="">&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-content">&ndash;&gt;-->
        <!--&lt;!&ndash;              <h4>建筑</h4>&ndash;&gt;-->
        <!--&lt;!&ndash;              <p>未来设计，即刻体验</p>&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          <div class="list-box">&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-cover">&ndash;&gt;-->
        <!--&lt;!&ndash;              <img src="https://cdn2.unrealengine.com/creatorfeed4-1659x1027-995bf4884a88.png?resize=1&w=518" alt="">&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-content">&ndash;&gt;-->
        <!--&lt;!&ndash;              <h4>汽车与运输</h4>&ndash;&gt;-->
        <!--&lt;!&ndash;              <p>创新的平台</p>&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          <div class="list-box">&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-cover">&ndash;&gt;-->
        <!--&lt;!&ndash;              <img src="https://cdn2.unrealengine.com/Unreal+Engine%2Findustry%2Fbroadcast-live-events%2FImagecourtesyof2n%2CWetaDigitalandWolfRothstein2018_img1-1920x1080-522893972987cc2712b3fcc5a5f5e84179bb4677.png?resize=1&w=518" alt="">&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-content">&ndash;&gt;-->
        <!--&lt;!&ndash;              <h4>广播与实况活动</h4>&ndash;&gt;-->
        <!--&lt;!&ndash;              <p>光彩夺目</p>&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          <div class="list-box">&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-cover">&ndash;&gt;-->
        <!--&lt;!&ndash;              <img src="https://cdn2.unrealengine.com/case-4-image-courtesy-of-precisionos-hd-1920x1080-7d1017c812e8.jpg?resize=1&w=518" alt="">&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-content">&ndash;&gt;-->
        <!--&lt;!&ndash;              <h4>训练与模拟</h4>&ndash;&gt;-->
        <!--&lt;!&ndash;              <p>来动真格的</p>&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          <div class="list-box">&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-cover">&ndash;&gt;-->
        <!--&lt;!&ndash;              <img src="https://cdn2.unrealengine.com/Unreal+Engine%2Fstudio%2FUE_4.23_web_Quixel_img-1640x1000-882d3b04c8f94d01c4d8df54fa3cdb98f749f9ea.jpg?resize=1&w=518" alt="">&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;            <div class="list-box-content">&ndash;&gt;-->
        <!--&lt;!&ndash;              <h4>其他应用</h4>&ndash;&gt;-->
        <!--&lt;!&ndash;              <p>面向所有人的实时技术</p>&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;          </div>&ndash;&gt;-->
        <!--        </div>-->
      </div>
      <!--详情说明模块-->
      <div class="illustrate-module">
        <div class="illustrate-module-content">
          <h2>{{ txt1 }}</h2>
          <p>{{ txt2 }}</p>
        </div>
        <div class="illustrate-module-tabs">
          <div class="tabs-box">
            <h3>自主研发</h3>
            <p>IP-BOX是100%由中国程序员自主研发，用于元宇宙的时空探索，追求更加丰富的视觉体验，助力人类探索元宇宙。</p>
            <button @click="onJumpDetail">了解更多</button>
          </div>
          <div class="tabs-box">
            <h3>技术先进</h3>
            <p>门槛降低，探索元宇宙更加便捷，帮助学校政企、小微品牌和直播领域降低采购成本，使用方便，符合市场需求</p>
            <button @click="onJumpDetail">了解更多</button>
          </div>
          <div class="tabs-box">
            <h3>界面友好</h3>
            <p>操作极其容易，无需专业人才方可操作，声光电融合、虚实交互结合的制片一体机，大大降低直播领域的采购成本</p>
            <button @click="onJumpDetail">了解更多</button>
          </div>
        </div>
        <div class="illustrate-module-news">
          <div class="news-title">
            <h2>超元创世新闻</h2>
            <div class="news-title-tag" @click="pageJump('/news')">
              <span>查看所有新闻</span>
            </div>
          </div>
          <div v-for="item in newsList" :key="item.id">
            <div class="news-cover" @click="pageJump('/detail',item.id)">
              <img :src="item.vCover" alt="">
            </div>
            <div class="news-info">
              <h4>新闻</h4>
              <h3 @click="pageJump('/detail',item.id)">{{ item.title }}</h3>
              <p>{{ item.subTitle }}</p>
              <div class="news-info-tag" @click="pageJump('/detail',item.id)">
                <span>了解详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--社区模块-->
      <div class="community-module">
        <div class="community-module-background"></div>
        <div class="community-module-gradient"></div>
        <div class="community-module-content">
          <div class="content-occupy"></div>
          <div class="content-container">
            <div class="content-container-detail">
              <div class="detail-title">Showings首映式</div>
              <div class="detail-text">Showings是一个致力于打造元宇宙数字资产商城的平台。它是一个由数字资产生产、交易和咨询服务构成的综合性平台，提供一站式数字资产商业服务。</div>
              <div class="detail-button">
                <button>
                  <a href="https://showings.cn" target="_blank">
                    <span>浏览平台</span>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div class="content-occupy"></div>
        </div>
      </div>
      <!--展示模块-->
      <div class="reveal-module">
        <div class="reveal-module-container">
          <div class="container-image">
            <img src="https://oss.cycsmeta.com/images/230113/13/9409d016e4884cb4b73e7abe4dcf0ebe.png" alt="">
          </div>
          <div class="container-info">
            <div class="container-info-detail">
              <a href="https://showings.cn" target="_blank">电动牙刷造型场景</a>
              <div class="from">来自：小拉大</div>
            </div>
            <div class="container-info-avatar">
              <img src="https://oss.cycsmeta.com/images/6800794448e140ebb4a75bcb20710a29.png" alt="">
            </div>
          </div>
        </div>
        <div class="reveal-module-container">
          <div class="container-image">
            <img src="https://oss.cycsmeta.com/images/230113/13/9224261c324c435693ecf72d0194d3fa.png" alt="">
          </div>
          <div class="container-info">
            <div class="container-info-detail">
              <a href="https://showings.cn" target="_blank">冰川电视发布会造型场景</a>
              <div class="from">来自：小拉大</div>
            </div>
            <div class="container-info-avatar">
              <img src="https://oss.cycsmeta.com/images/6800794448e140ebb4a75bcb20710a29.png" alt="">
            </div>
          </div>
        </div>
        <div class="reveal-module-container">
          <div class="container-image">
            <img src="https://oss.cycsmeta.com/images/230113/13/ff9d3ff964d54c6ab66ddd1f5188a6bf.png" alt="">
          </div>
          <div class="container-info">
            <div class="container-info-detail">
              <a href="https://showings.cn" target="_blank">灯塔造型场景</a>
              <div class="from">来自：L</div>
            </div>
            <div class="container-info-avatar">
              <img src="https://oss.cycsmeta.com/images/17f6f50f09e54f65803e2ce0fe1ed176.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!--获取模块-->
      <div class="obtain-module" style="display: none;">
        <div class="obtain-module-introduce">
          <div class="introduce-title">
            <h2>免费下载，功能齐备！</h2>
            <p>虚幻引擎功能齐备，开箱即用，并提供所有功能及完整的源代码访问权限。</p>
          </div>
          <div class="obtain-module-tabs">
            <div class="tabs-container">
              <h4>获取虚幻引擎</h4>
              <div class="tabs-container-detail">
                <h3>免费开启虚幻之旅！</h3>
                <p>你可以免费使用虚幻引擎创建线性内容、自定义项目和内部项目。它也可以被免费用于游戏开发——只有当你的产品营收超过100万美元时才需要支付5%的分成费用。</p>
              </div>
            </div>
          </div>
          <div class="obtain-module-occupy"></div>
          <div class="obtain-module-tabs">
            <div class="tabs-container">
              <h4>和我们谈谈</h4>
              <div class="tabs-container-detail">
                <h3>自定义许可</h3>
                <p>需要高级支持、自定义条款或与Epic Games建立直接联系吗？欢迎就企业许可或自定义解决方案与我们沟通。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="obtain-module-communication">
          <div class="communication-background"></div>
          <div class="communication-container">
            <div class="communication-container-content">
              <h4>新闻通讯</h4>
              <h3>获取关于行业创新的新闻及最新免费资产</h3>
              <div class="content-operate">
                <div class="content-operate-input">
                  <input type="text" placeholder="电子邮箱">
                </div>
                <div class="content-operate-button">
                  <button>订阅</button>
                </div>
              </div>
              <p>
                通过提交您的信息，即表示您想收到Epic Games的新闻、调查问卷和特惠推送。
                <a href="">隐私政策</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScaleBanner from '../components/ScaleBanner/ScaleBanner.vue';
import api from "@/utils/api";

export default {
  components: {
    ScaleBanner,
  },
  data() {
    return {
      dropdownSelection: false,
      scrollHeight: 0,
      videoTransform: 'none',
      videoOpacity: 1,
      contentTransform: 'translateY(1px) translateZ(0px)',
      contentOpacity: 1,
      contentEvents: 'all',
      scrollOver: false,
      newsList: [],
      txt1: 'IP-BOX 元宇宙虚拟制片一体机',
      txt2: 'IP-BOX是一款高效、易操作的声光电+虚拟场景的制片一体机，独有硬件配合超元创世公司开发的Utopia乌托邦元宇宙制片引擎和Showings元宇宙素材商城形成完整的元宇宙制片解决方案。',
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll);
    })
    this.getArticleList();
  },
  methods: {
    onJumpDetail() {
      this.$router.push('/home/index');
    },
    handleScroll() {
      this.scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollHeight < 100) {
        this.videoTransform = "none";
        this.videoOpacity = 1;
        this.contentTransform = "translateY(1px) translateZ(0px)";
        this.contentOpacity = 1;
        this.contentEvents = 'all';
      }
      if (this.scrollHeight >= 100) {
        this.videoOpacity = 0.966667;
        this.contentTransform = 'translateY(-33px) translateZ(0px)';
        this.contentOpacity = 0.666667;
      }
      if (this.scrollHeight >= 200) {
        this.videoOpacity = 0.933333;
        this.contentTransform = 'translateY(-200px) translateZ(0px)';
        this.contentOpacity = 0.333333;
      }
      if (this.scrollHeight >= 300) {
        this.videoTransform = "translateY(0px) scaleX(0.959971) scaleY(0.956658) translateZ(0px)";
        this.videoOpacity = 0.9;
        this.contentTransform = 'translateY(-500px) translateZ(0px)';
        this.contentOpacity = 0;
        this.contentEvents = 'none';
      }
      if (this.scrollHeight >= 400) {
        this.videoTransform = "translateY(0px) scaleX(0.879913) scaleY(0.869974) translateZ(0px)";
        this.videoOpacity = 0.866667;
      }
      if (this.scrollHeight >= 500) {
        this.videoTransform = "translateY(0px) scaleX(0.799854) scaleY(0.783291) translateZ(0px)";
        this.videoOpacity = 0.833333;
      }
      if (this.scrollHeight >= 600) {
        this.videoTransform = "translateY(-118.65px) scaleX(0.719796) scaleY(0.696607) translateZ(0px)";
        this.videoOpacity = 0.8;
      }
      if (this.scrollHeight >= 700) {
        this.videoTransform = "translateY(-237.3px) scaleX(0.639738) scaleY(0.609923) translateZ(0px)";
        this.videoOpacity = 0.766667;
      }
      if (this.scrollHeight >= 800) {
        this.videoTransform = "translateY(-355.95px) scaleX(0.55968) scaleY(0.523239) translateZ(0px)";
        this.videoOpacity = 0.733333;
      }
      if (this.scrollHeight >= 900) {
        this.videoTransform = "translateY(-474.6px) scaleX(0.479622) scaleY(0.436555) translateZ(0px)";
        this.videoOpacity = 0.7;
        this.scrollOver = false;
      }
      if (this.scrollHeight >= 1000) {
        this.videoTransform = "translateY(-518.5px) scaleX(0.45) scaleY(0.404482) translateZ(0px)";
        this.videoOpacity = 0.4;
        this.scrollOver = true;
      }
    },
    getArticleList() {
      api.getArticleData({
        current: 1,
        size: 0,
        type: 1,
        isPublish: 1,
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.records.forEach(item => {
            if (item.id === '11' || item.id === '10') {
              this.newsList.push(item);
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
//pc端
@media only screen and (min-device-width: 700px) {
  .pc-layout {
    width: 100%;
    margin-top: 52px;
    overflow: visible;
    position: relative;

    //视频模块
    .video-module {
      width: 100%;
      height: calc(100vh + 500px);
      background: rgb(15, 17, 41);
      position: relative;
      overflow: hidden;
      pointer-events: none;
      z-index: 8;

      .video-module-container {
        width: 100vw;
        height: 100vh;
        position: fixed;
        bottom: 0;
        left: 0;
        overflow: hidden;

        .container-info {
          width: 100%;
          max-width: 100%;
          height: 100%;
          position: relative;
          inset: 0;

          video {
            width: 100%;
            object-fit: cover;
            object-position: 50% 0;
            display: inline-block;
            vertical-align: baseline;
          }
        }
      }

      .video-module-complete {
        bottom: -400px;
        position: absolute;
      }

      .video-module-content {
        width: 700px;
        position: fixed;
        bottom: 10%;
        left: 5%;
        right: 0;
        z-index: 100;

        h1 {
          font-weight: 300;
          font-size: 66px;
          line-height: 74px;
          letter-spacing: -1px;
          color: #ffffff;
        }

        .content-occupy {
          height: 72px;
        }

        .content-understand {
          width: 100%;

          button {
            max-width: 100%;
            min-width: 155px;
            min-height: 48px;
            background: none;
            padding: 12px 24px;
            color: #ffffff;
            cursor: pointer;
            border: 2px solid rgb(0, 171, 245);
            transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99) 0s;

            span {
              font-weight: 500;
              letter-spacing: 2px;
              font-size: 13px;
              line-height: 16px;
              color: inherit;
            }
          }

          button:hover {
            box-shadow: rgb(0 171 245) 0 0 18px;
          }
        }

        .content-slider {
          min-height: 40px;
          position: relative;
          transform: translateY(0%);
          z-index: 1;

          .content-slider-box {
            width: 2px;
            height: 40px;
            position: relative;
            text-align: center;

            span {
              width: 2px;
              height: 0;
              content: "";
              background: rgb(255, 255, 255);
              display: block;
              position: absolute;
              top: -40px;
              left: 0;
              animation: iikemT 2s infinite cubic-bezier(0, 0.42, 0.2, 0.99) running;
            }

            @keyframes iikemT {
              0% {
                top: 0;
                height: 0;
              }
              40% {
                top: 0;
                height: 80px;
              }
              85% {
                top: 80px;
                height: 0;
              }
              100% {
                top: 80px;
                height: 0;
              }
            }
          }
        }
      }
    }

    //轮播模块
    .banner-module {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    //新闻模块
    .news-module {
      width: 100%;
      background: rgb(219, 222, 227);
      color: rgb(20, 20, 20);
      padding: 96px 0 0;
      box-sizing: border-box;

      .news-module-info {
        max-width: 1456px;
        margin: 0 auto;
        padding: 0 64px;
        box-sizing: border-box;
        text-align: center;

        h2 {
          color: #000000;
          font-weight: 300;
          font-size: 50px;
          line-height: 58px;
          letter-spacing: -0.5px;
        }

        .info-text {
          max-width: 50%;
          margin: 16px auto 0;

          span {
            color: rgb(56, 59, 80);
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }

      .news-module-boxes {
        max-width: 1456px;
        margin: 96px auto 0;
        padding: 0 64px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .box {
          max-width: 33.33%;
          padding: 12px 12px 36px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          position: relative;

          .box-title {
            width: 100%;
            margin-top: 32px;
            text-align: left;

            span {
              color: #141414;
              font-weight: 400;
              font-size: 26px;
              line-height: 34px;
              cursor: pointer;
            }

            span:hover {
              color: rgb(0, 87, 217);
            }
          }

          .box-content {
            width: 100%;
            margin-top: 8px;
            font-weight: 400;
            color: rgb(56, 59, 80);
            font-size: 16px;
            line-height: 30px;
          }

          .box-button {
            width: 100%;
            margin-top: 32px;
            text-align: left;

            button {
              max-width: 100%;
              min-width: 155px;
              min-height: 48px;
              background: none;
              padding: 12px 24px;
              border: 2px solid rgb(0, 171, 245);
              border-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%) 5 / 2px / 0 stretch;
              transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99) 0s;
              cursor: pointer;

              span {
                color: #141414;
                font-weight: 500;
                letter-spacing: 2px;
                font-size: 13px;
                line-height: 16px;
              }
            }

            button:hover {
              border-color: rgb(0, 87, 217);
              border-image: radial-gradient(100% 100% at 100% 100%, rgb(0, 87, 217) 0%, rgb(0, 87, 217) 100%) 5 / 1 / 0 stretch;
              box-shadow: rgb(0, 87, 217) 0 0 18px;
            }
          }
        }

        .box::before {
          width: calc(100% - 24px);
          height: 2px;
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
        }
      }

      .news-module-example {
        max-width: 1456px;
        margin: 96px auto 0;
        padding: 0 64px;
        box-sizing: border-box;

        .example-title {
          width: 100%;
          padding-bottom: 24px;
          border-bottom: 1px solid rgb(56, 59, 80);
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .example-title-left {
            color: #000000;
            font-weight: 300;
            font-size: 34px;
            line-height: 42px;
          }

          .example-title-right {
            padding-bottom: 4px;
            background-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 2px;
            color: #141414;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 13px;
            cursor: pointer;
          }
        }

        .example-occupy {
          width: 100%;
          height: 40px;
        }

        .example-imgs {
          width: calc(100% + 24px);
          margin: -12px;
          display: flex;
          justify-content: center;
          align-items: center;

          .example-imgs-box {
            width: 50%;
            flex-basis: 50%;
            padding: 12px;
            box-sizing: border-box;
            overflow: hidden;

            .box-cover {
              width: 100%;
              height: 100%;
              position: relative;
              overflow: hidden;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 0;
                transition: all 0.3s ease 0s;
              }

              img:hover {
                transition: transform 0.5s ease 0s;
                transform: scale(1.1);
              }
            }
          }
        }

        .example-content {
          width: calc(100% + 24px);
          margin: 0 -12px;
          padding-bottom: 96px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          .example-content-area {
            max-width: 50%;
            flex-basis: 50%;
            padding: 32px 12px 36px;
            box-sizing: border-box;

            .area-tab {
              width: 100%;
              text-align: left;
              font-size: 16px;
              color: #141414;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 3px;
            }

            .area-title {
              width: 100%;
              margin-top: 16px;
              text-align: left;

              span {
                color: #141414;
                font-weight: 400;
                font-size: 26px;
                line-height: 34px;
                cursor: pointer;
              }

              span:hover {
                color: rgb(0, 87, 217);
              }
            }

            .area-introduce {
              width: 100%;
              color: rgb(56, 59, 80);
              font-weight: 400;
              font-size: 16px;
              line-height: 30px;
            }

            .area-button {
              width: 100%;
              margin-top: 32px;

              span {
                padding-bottom: 4px;
                background-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
                background-position: 0 100%;
                background-repeat: no-repeat;
                background-size: 100% 2px;
                color: #141414;
                font-weight: 500;
                letter-spacing: 2px;
                font-size: 13px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    //社区模块
    .community-module {
      width: 100%;
      position: relative;

      .community-module-background {
        height: 100%;
        background: url(https://cdn2-unrealengine-1251447533.file.myqcloud.com/793da53d3fe4a9d3b58c20175bc58210-1920x960-f927f18875ae.jfif?resize=1&w=1920) 0 0 / cover no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0.25;
      }

      .community-module-gradient {
        width: 100%;
        height: 33%;
        background: linear-gradient(0deg, rgb(14, 17, 40) 0%, rgba(13, 16, 35, 0) 100%);
        position: absolute;
        bottom: 0;
      }

      .community-module-content {
        width: 100%;
        position: relative;
        z-index: 2;

        .content-occupy {
          width: 100%;
          height: 128px;
        }

        .content-container {
          max-width: 1456px;
          margin: 0 auto;
          padding: 0 64px;
          box-sizing: border-box;

          .content-container-detail {
            width: calc(100% + 32px);
            margin: -16px;
            display: flex;
            justify-content: center;
            align-items: center;

            .detail-occupy {
              max-width: 16.666667%;
              flex-basis: 16.666667%;
            }

            .detail-info {
              max-width: 66.666667%;
              flex-basis: 66.666667%;
              padding: 16px;
              box-sizing: border-box;

              .detail-info-title {
                width: 100%;
                text-align: center;
                color: #ffffff;
                font-weight: 300;
                font-size: 50px;
                line-height: 58px;
                letter-spacing: -0.5px;
              }

              .detail-info-text {
                width: 100%;
                margin-top: 16px;
                color: #ffffff;
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
              }

              .detail-info-button {
                width: 100%;
                margin-top: 32px;
                text-align: center;

                button {
                  max-width: 100%;
                  min-width: 155px;
                  min-height: 48px;
                  background: none;
                  padding: 12px 24px;
                  color: #ffffff;
                  border: 2px solid rgb(0, 171, 245);
                  transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99) 0s;
                  cursor: pointer;

                  a {
                    color: inherit;

                    span {
                      font-weight: 500;
                      letter-spacing: 2px;
                      font-size: 13px;
                      line-height: 16px;
                      color: inherit;
                    }
                  }
                }

                button:hover {
                  box-shadow: rgb(0 171 245) 0 0 18px;
                }
              }
            }
          }
        }
      }
    }

    //展示模块
    .reveal-module {
      max-width: 1456px;
      margin: 32px auto 0;
      padding: 0 64px;
      box-sizing: border-box;

      .reveal-module-container {
        width: calc(100% + 32px);
        margin: -16px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .container-show {
          max-width: 33.33%;
          flex-basis: 33.33%;
          padding: 16px 16px 88px;
          box-sizing: border-box;

          .container-show-box {
            width: 100%;
            margin-right: 40px;

            .box-image {
              width: 100%;
              height: 381.328px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 0;
                transition: all 0.3s ease 0s;
              }

              img:hover {
                transform: scale(1.1);
              }
            }

            .box-image2 {
              width: 100%;
              height: 218.48px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 0;
                transition: all 0.3s ease 0s;
              }

              img:hover {
                transform: scale(1.1);
              }
            }

            .box-info {
              width: 100%;
              margin-top: 32px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .box-info-detail {
                width: calc((100% - 56px) - 24px);
                position: relative;
                top: -8px;

                a {
                  color: #ffffff;
                  font-weight: 400;
                  font-size: 26px;
                  line-height: 34px;
                }

                a:hover {
                  color: rgb(0, 171, 245);
                }

                .from {
                  color: rgb(219, 222, 227);
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0.2px;
                }
              }

              .box-info-avatar {
                width: 56px;
                height: 56px;
                margin-left: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 56px;
                  object-fit: cover;
                  object-position: 50% 0;
                }
              }
            }
          }
        }
      }
    }

    //获取模块
    .obtain-module {
      width: 100%;
      background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
      padding-top: 96px;
      box-sizing: border-box;

      .obtain-module-container {
        max-width: 1456px;
        margin: 0 auto;
        padding: 0 64px;
        box-sizing: border-box;

        .container-content {
          width: calc(100% + 32px);
          margin: 0 -16px;
          display: flex;

          .container-content-left {
            max-width: 33.33%;
            flex-basis: 33.33%;
            padding: 0 16px;
            box-sizing: border-box;

            .left-info {
              width: calc(100% + 32px);
              margin: 0 -16px;
              box-sizing: border-box;
              display: flex;
              align-items: center;

              .left-info-content {
                max-width: 83.33%;
                flex-basis: 83.33%;
                padding: 0 16px;
                box-sizing: border-box;

                .content-title {
                  width: 100%;
                  color: #000000;
                  font-weight: 300;
                  font-size: 50px;
                  line-height: 58px;
                  letter-spacing: -0.5px;
                }

                .content-text {
                  width: 100%;
                  margin-top: 24px;
                  color: #000000;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 30px;
                }
              }
            }
          }

          .container-content-right {
            max-width: 66.66%;
            flex-basis: 66.66%;
            padding: 0 16px;
            box-sizing: border-box;

            .right-info {
              width: calc(100% + 32px);
              margin: 0 -16px;
              box-sizing: border-box;
              display: flex;
              justify-content: flex-end;

              .right-info-box {
                max-width: none;
                flex-basis: auto;
                padding: 0 16px;
                box-sizing: border-box;

                .box-content {
                  max-width: 416px;
                  min-height: 494px;
                  padding: 48px;
                  border: 4px solid #ffffff;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  cursor: pointer;

                  .box-content-top {
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: 3px;
                  }

                  .box-content-occupy {
                    width: 100%;
                    height: 64px;
                  }

                  .box-content-bottom {
                    width: 100%;
                    margin-top: auto;

                    .bottom-title {
                      color: #000000;
                      font-weight: 300;
                      font-size: 34px;
                      line-height: 42px;
                    }

                    .bottom-text {
                      margin-top: 8px;
                      color: #000000;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 30px;
                    }
                  }
                }

                .box-content:hover {
                  background-color: #ffffff;
                }
              }
            }
          }
        }
      }

      .obtain-module-communication {
        width: 100%;
        margin-top: 128px;
        position: relative;
        text-align: center;

        .communication-background {
          width: 100%;
          background-color: rgb(32, 35, 55);
          position: absolute;
          inset: 50% 0 0;
        }

        .communication-container {
          max-width: 1456px;
          margin: 0 auto;
          padding: 0 64px;
          box-sizing: border-box;
          position: relative;
          z-index: 10;

          .communication-container-content {
            width: 100%;
            background-color: #ffffff;
            padding: 72px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .content-title {
              color: rgb(14, 17, 40);
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: 3px;
            }

            .content-info {
              max-width: 66.66%;
              flex-basis: 66.66%;
              margin-top: 24px;
              display: flex;
              justify-content: center;
              align-items: center;

              .content-info-text {
                color: rgb(14, 17, 40);
                font-weight: 300;
                font-size: 34px;
                line-height: 42px;
              }

              .content-info-select {
                margin-left: 20px;
                position: relative;
                cursor: pointer;

                .select-name {
                  padding-right: 20px;
                  box-sizing: border-box;
                  color: rgb(14, 17, 40);
                  font-weight: 300;
                  font-size: 34px;
                  line-height: 42px;
                }

                .select-arrow {
                  width: 0;
                  height: 6px;
                  display: inline-block;
                  position: absolute;
                  top: 50%;
                  right: 6px;
                  transform: translateY(-50%) rotate(0deg);
                  transition: all 0.4s ease 0s;
                }

                .select-arrow::before {
                  width: 3px;
                  content: "";
                  background-color: transparent;
                  border-bottom: 12px solid rgb(0, 87, 217);
                  display: inline-block;
                  position: absolute;
                  top: -1px;
                  left: 0;
                  //transform: rotate(-45deg);
                  transform: rotate(-135deg);
                  transition: all 0.4s ease 0s;
                }

                .select-arrow::after {
                  width: 3px;
                  content: "";
                  background-color: transparent;
                  border-bottom: 12px solid rgb(0, 87, 217);
                  display: inline-block;
                  position: absolute;
                  top: -1px;
                  left: -7px;
                  //transform: rotate(45deg);
                  transform: rotate(135deg);
                  transition: all 0.4s ease 0s;
                }
              }

              .content-info-select::before {
                width: 100%;
                height: 2px;
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
              }
            }

            .content-subscribe {
              width: 60%;
              margin-top: 32px;
              display: flex;
              justify-content: center;

              .content-subscribe-input {
                width: 42%;
                height: 48px;
                background: rgb(236, 239, 242);
                padding: 8px;
                box-sizing: border-box;

                input {
                  width: 100%;
                  height: 100%;
                  background: rgb(236, 239, 242);
                  padding: 0 8px;
                  box-sizing: border-box;
                  font-size: 16px;
                  line-height: 48px;
                  border: none;
                  outline: none;
                }
              }

              .content-subscribe-button {
                width: 25%;

                button {
                  width: 100%;
                  height: 48px;
                  background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
                  padding: 12px 24px;
                  border: none;
                  box-sizing: border-box;
                  color: rgb(14, 17, 40);
                  transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99) 0s;
                  cursor: pointer;

                  span {
                    font-weight: 500;
                    letter-spacing: 2px;
                    font-size: 13px;
                    line-height: 16px;
                    color: inherit;
                  }
                }

                button:hover {
                  box-shadow: rgb(0, 87, 217) 0 0 18px;
                  background: radial-gradient(100% 100% at 100% 100%, rgb(0, 87, 217) 0%, rgb(0, 87, 217) 100%);
                }
              }
            }

            .content-explain {
              margin-top: 25px;
              display: flex;
              justify-content: center;

              p {
                color: #000000;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.2px;
              }

              a {
                color: rgb(0, 87, 217);
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.2px;
              }
            }
          }
        }
      }
    }
  }

  .mobile-layout {
    display: none;
  }
}

//移动端
@media only screen and (max-device-width: 700px) {
  .pc-layout {
    display: none;
  }

  .mobile-layout {
    width: 100%;
    padding-top: 52px;
    box-sizing: border-box;

    //产品应用模块
    .apply-module {
      width: 100%;
      position: relative;

      .apply-module-background {
        width: 100%;
        height: calc(100vh - 52px);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;

        .background-container {
          width: 100%;
          height: calc(100vh - 52px);
          //background: url("https://cdn2.unrealengine.com/main-1300x1251-f2feb1dde917.png?resize=1&w=800") center center / cover no-repeat;

          .container-info {
            width: 100%;
            max-width: 100%;
            height: 100%;
            position: relative;
            inset: 0;

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 0;
              display: inline-block;
              vertical-align: baseline;
            }
          }

          .background-container-mask {
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            height: calc(100vh - 52px);
            background: linear-gradient(0deg, rgba(14, 17, 40, 0.5), rgba(13, 16, 35, 0.1) 100%);
          }

        }
      }

      .apply-module-content {
        width: 100%;
        height: calc(100vh - 52px);
        position: relative;
        top: 0;
        left: 0;
        right: 0;

        .content-container {
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          text-align: center;
          position: absolute;
          bottom: 0;
          transform: translateY(-60%);
          z-index: 2;

          h1 {
            color: #ffffff;
            font-weight: 300;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          button {
            min-width: 155px;
            min-height: 48px;
            background: none;
            margin-top: 24px;
            padding: 12px 24px;
            border: 2px solid rgb(0, 171, 245);
            border-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%) 5 / 2px / 0 stretch;
            box-sizing: border-box;
            color: #ffffff;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 13px;
            line-height: 16px;
          }

          .content-container-slider {
            min-height: 40px;
            margin-top: 56px;
            position: relative;
            transform: translateY(0%);
            z-index: 1;

            .slider-box {
              width: 2px;
              height: 40px;
              margin: 0 auto;
              position: relative;
              text-align: center;

              span {
                width: 2px;
                height: 0;
                content: "";
                background: rgb(255, 255, 255);
                display: block;
                position: absolute;
                top: -40px;
                left: 0;
                animation: iikemT 2s infinite cubic-bezier(0, 0.42, 0.2, 0.99) running;
              }

              @keyframes iikemT {
                0% {
                  top: 0;
                  height: 0;
                }
                40% {
                  top: 0;
                  height: 80px;
                }
                85% {
                  top: 80px;
                  height: 0;
                }
                100% {
                  top: 80px;
                  height: 0;
                }
              }
            }
          }
        }
      }

      .apply-module-list {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;

        .list-box {
          width: 100%;
          margin-bottom: 24px;
          padding-right: 40px;
          box-sizing: border-box;

          .list-box-cover {
            width: 100%;
            position: relative;
            aspect-ratio: 16 / 9;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 0;
            }
          }

          .list-box-cover::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80%;
            opacity: 0.9;
            background: linear-gradient(360deg, rgb(14, 17, 40) 0%, rgba(0, 0, 0, 0) 100%);
          }

          .list-box-content {
            width: 100%;
            height: auto;
            margin-top: -25px;
            padding: 0 0 32px 32px;
            box-sizing: border-box;
            text-align: left;
            position: relative;
            left: 0;

            h4 {
              color: #ffffff;
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: 3px;
            }

            p {
              margin-top: 24px;
              color: #ffffff;
              font-weight: 300;
              font-size: 30px;
              line-height: 38px;
            }
          }
        }

        .list-box:nth-of-type(2n) {
          padding-right: 0;
          padding-left: 40px;

          .list-box-content {
            padding: 0 32px 32px 0;
            text-align: right;
          }
        }
      }
    }

    //详情说明模块
    .illustrate-module {
      width: 100%;
      background: rgb(219, 222, 227);
      padding: 56px 0;
      box-sizing: border-box;

      .illustrate-module-content {
        width: 100%;
        padding: 0 16px 40px;
        box-sizing: border-box;
        text-align: center;

        h2 {
          color: #000000;
          font-weight: 300;
          font-size: 38px;
          line-height: 46px;
          letter-spacing: -0.5px;
        }

        p {
          margin-top: 16px;
          color: rgb(56, 59, 80);
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
        }
      }

      .illustrate-module-tabs {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;

        .tabs-box {
          width: 100%;
          padding: 24px 0 40px;
          box-sizing: border-box;
          position: relative;

          h3 {
            color: #141414;
            font-weight: 400;
            font-size: 22px;
            line-height: 30px;
          }

          p {
            margin-top: 8px;
            color: rgb(56, 59, 80);
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }

          button {
            min-width: 155px;
            min-height: 48px;
            background: none;
            margin-top: 24px;
            padding: 12px 24px;
            border: 2px solid rgb(0, 171, 245);
            border-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%) 5 / 2px / 0 stretch;
            box-sizing: border-box;
            color: #141414;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 13px;
            line-height: 16px;
          }
        }

        .tabs-box::before {
          width: 100%;
          height: 2px;
          content: "";
          background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .illustrate-module-news {
        width: 100%;
        margin-top: 56px;
        padding: 0 16px;
        box-sizing: border-box;

        .news-title {
          width: 100%;
          padding-bottom: 16px;
          border-bottom: 1px solid rgb(56, 59, 80);
          box-sizing: border-box;

          h2 {
            color: rgb(0, 0, 0);
            font-weight: 300;
            font-size: 30px;
            line-height: 38px;
          }

          .news-title-tag {
            margin-top: 32px;

            span {
              padding-bottom: 4px;
              background-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
              background-position: 0 100%;
              background-repeat: no-repeat;
              background-size: 100% 2px;
              color: #141414;
              font-weight: 500;
              letter-spacing: 2px;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }

        .news-cover {
          width: 100%;
          margin-top: 24px;
          aspect-ratio: 16 / 9;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
          }
        }

        .news-info {
          width: 100%;
          padding: 24px 0 28px;
          box-sizing: border-box;

          h4 {
            color: #141414;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 3px;
          }

          h3 {
            margin-top: 16px;
            color: #141414;
            font-weight: 400;
            font-size: 22px;
            line-height: 30px;
          }

          p {
            margin-top: 8px;
            color: rgb(56, 59, 80);
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }

          .news-info-tag {
            margin-top: 24px;

            span {
              padding-bottom: 4px;
              background-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
              background-position: 0 100%;
              background-repeat: no-repeat;
              background-size: 100% 2px;
              color: #141414;
              font-weight: 500;
              letter-spacing: 2px;
              line-height: 16px;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }
      }
    }

    //社区模块
    .community-module {
      width: 100%;
      position: relative;
      text-align: center;

      .community-module-background {
        height: 100%;
        background: url(https://cdn2-unrealengine-1251447533.file.myqcloud.com/793da53d3fe4a9d3b58c20175bc58210-1920x960-f927f18875ae.jfif?resize=1&w=1920) 0 0 / cover no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0.25;
      }

      .community-module-gradient {
        width: 100%;
        height: 33%;
        background: linear-gradient(0deg, rgb(14, 17, 40) 0%, rgba(13, 16, 35, 0) 100%);
        position: absolute;
        bottom: 0;
      }

      .community-module-content {
        width: 100%;
        position: relative;
        z-index: 2;

        .content-occupy {
          width: 100%;
          height: 56px;
        }

        .content-container {
          width: 100%;
          margin: 0 auto;

          .content-container-detail {
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;

            .detail-title {
              width: 100%;
              text-align: center;
              color: #ffffff;
              font-weight: 300;
              font-size: 38px;
              line-height: 46px;
              letter-spacing: -0.5px;
            }

            .detail-text {
              width: 100%;
              margin-top: 16px;
              color: #ffffff;
              text-align: center;
              font-weight: 400;
              font-size: 16px;
              line-height: 30px;
            }

            .detail-button {
              width: 100%;
              margin-top: 32px;
              text-align: center;

              button {
                max-width: 100%;
                min-width: 155px;
                min-height: 48px;
                background: none;
                padding: 12px 24px;
                color: #ffffff;
                border: 2px solid rgb(0, 171, 245);
                transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99) 0s;
                cursor: pointer;

                a {
                  color: inherit;

                  span {
                    font-weight: 500;
                    letter-spacing: 2px;
                    font-size: 13px;
                    line-height: 16px;
                    color: inherit;
                  }
                }
              }

              button:hover {
                box-shadow: rgb(0 171 245) 0 0 18px;
              }
            }
          }
        }
      }
    }

    //展示模块
    .reveal-module {
      width: 100%;
      padding: 24px 16px 0;
      box-sizing: border-box;

      .reveal-module-container {
        width: 100%;
        padding-bottom: 32px;
        box-sizing: border-box;

        .container-image {
          width: 100%;
          aspect-ratio: 16 / 9;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
          }
        }

        .container-image2 {
          width: 100%;
          height: 218.48px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
            transition: all 0.3s ease 0s;
          }

          img:hover {
            transform: scale(1.1);
          }
        }

        .container-info {
          width: 100%;
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .container-info-detail {
            width: calc((100% - 56px) - 24px);
            position: relative;
            top: -8px;

            a {
              color: #ffffff;
              font-weight: 400;
              font-size: 22px;
              line-height: 30px;
            }

            .from {
              color: rgb(219, 222, 227);
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.2px;
            }
          }

          .container-info-avatar {
            width: 56px;
            height: 56px;
            margin-left: 24px;
            aspect-ratio: 1 / 1;

            img {
              width: 100%;
              height: 100%;
              border-radius: 56px;
              object-fit: cover;
              object-position: 50% 0;
            }
          }
        }
      }
    }

    //获取模块
    .obtain-module {
      width: 100%;
      background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
      padding-top: 56px;
      box-sizing: border-box;

      .obtain-module-introduce {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;

        .introduce-title {
          width: 100%;
          padding: 0 8px 40px;
          box-sizing: border-box;

          h2 {
            color: #000000;
            font-weight: 300;
            font-size: 38px;
            line-height: 46px;
            letter-spacing: -0.5px;
          }

          p {
            margin-top: 16px;
            color: #000000;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }
        }

        .obtain-module-tabs {
          width: 100%;

          .tabs-container {
            width: 100%;
            padding: 24px;
            border: 4px solid #ffffff;
            box-sizing: border-box;

            h4 {
              color: #000000;
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: 3px;
            }

            .tabs-container-detail {
              width: 100%;
              margin-top: 64px;

              h3 {
                color: #000000;
                font-weight: 300;
                font-size: 30px;
                line-height: 38px;
              }

              p {
                margin-top: 8px;
                color: #000000;
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
              }
            }
          }
        }

        .obtain-module-occupy {
          width: 100%;
          height: 24px;
        }
      }

      .obtain-module-communication {
        width: 100%;
        margin-top: 56px;
        position: relative;

        .communication-background {
          width: 100%;
          background-color: rgb(32, 35, 55);
          position: absolute;
          inset: 50% 0 0;
        }

        .communication-container {
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          position: relative;
          z-index: 10;

          .communication-container-content {
            width: 100%;
            background: rgb(255, 255, 255);
            padding: 56px 16px;
            box-sizing: border-box;
            position: relative;
            z-index: 10;
            text-align: center;

            h4 {
              color: rgb(14, 17, 40);
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: 3px;
            }

            h3 {
              margin-top: 24px;
              color: rgb(14, 17, 40);
              font-weight: 400;
              font-size: 20px;
              line-height: 28px;
            }

            .content-operate {
              width: 66%;
              margin: 32px auto 0;
              text-align: center;

              .content-operate-input {
                width: 100%;
                height: 48px;
                background: rgb(236, 239, 242);
                padding: 8px;
                box-sizing: border-box;

                input {
                  width: 100%;
                  height: 100%;
                  background: rgb(236, 239, 242);
                  padding: 0 8px;
                  box-sizing: border-box;
                  font-size: 16px;
                  line-height: 48px;
                  border: none;
                  outline: none;
                }
              }

              .content-operate-button {
                width: 100%;
                height: 48px;
                margin-top: 14px;

                button {
                  width: 100%;
                  height: 100%;
                  background: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
                  padding: 12px 24px;
                  border: none;
                  box-sizing: border-box;
                  color: rgb(14, 17, 40);
                }
              }
            }

            p {
              margin-top: 24px;
              color: #000000;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.2px;

              a {
                color: rgb(0, 87, 217);
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.2px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
